import React from 'react'

import headerImage from './../images/header/leda-homecare-home-carers.png'
import Layout from '../components/layout'

const ContactUs = () => (
  <Layout>
    <div className="contact-us">
      <div
        className="header-image"
        style={{ backgroundImage: `url(${headerImage})` }}
      />
      <div className="container" style={{ marginTop: '80px' }}>
        <div className="row">
          <h1 className="center-text">Contact Us</h1>
          <div className="columns twelve">
            <p className="center-text">
              At Leda Homecare, we would love to talk to you about what your
              care needs are and how we can help you. There are many ways you
              can get in touch with us. Please use the form below, call us on
              01909 512550, or email{' '}
              <a href="mailto:admin@ledahomecare.co.uk">
                admin@ledahomecare.co.uk.{' '}
              </a>
            </p>
            <p className="center-text">
              You can find us at: Suite G1, Carriage Court, Welbeck, Worksop,
              S80 3LR
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ContactUs
